import React from 'react';

import { isMobile } from "react-device-detect";
import Header from '../../Header/Header';
import MobileHeader from '../../Header/MobileHeader';
import Footer from '../Footer'
import './Entertainment.css';

function Magician() {
    return (
        <>
            {
                isMobile ? (<><MobileHeader /></>) : (<><Header /></>)
            }
            <section id="virtualEvent">
                <div className="container">
                    <div className="main-heading mt-5">
                        <h2>Magician</h2>
                        <h5>Dreams Event</h5>
                    </div>
                </div>
            </section>

            <section className="space-extra-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-8 col-lg-8">
                            <div className="page-single">
                                <div className="page-img">
                                    <img
                                        src="assets/img/magician.jpg"
                                        alt="Service Image"
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div className="page-content">
                                    <h2 className="h3 page-title">Magician</h2>
                                    <p>
                                        Prepare to be spellbound by the enchanting performances of our skilled magicians. Infusing wonder and awe into your event, our magicians captivate audiences of all ages with their mesmerizing illusions and mind-bending tricks. Their ability to create moments of sheer magic adds a touch of fascination and excitement, leaving your guests astonished and talking about your event long after the curtain falls.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-lg-4">
                            <aside className="sidebar-area">
                                <div className="widget widget_banner">
                                    <h4 className="widget_title">Book Now</h4>
                                    <div className="widget-banner">
                                        <form
                                            action="https://angfuzsoft.com/html/souler/demo/mail.php"
                                            method="POST"
                                            className="rsvp-form"
                                        >
                                            <div className="row">
                                                <div className="form-group col-sm-12">
                                                    <input
                                                        type="text"
                                                        className="form-control style2"
                                                        name="name"
                                                        id="name"
                                                        placeholder="Your Name"
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12">
                                                    <input
                                                        type="email"
                                                        className="form-control style2"
                                                        name="email"
                                                        id="email"
                                                        placeholder="Your Email"
                                                    />
                                                </div>

                                                <div className="form-group col-sm-12">
                                                    <input
                                                        type="number"
                                                        className="form-control style2"
                                                        name="guest"
                                                        id="guest"
                                                        placeholder="Phone Number"
                                                    />
                                                </div>



                                                <div className="form-btn col-12">
                                                    <button className="btnAbout style2">SEND</button>
                                                </div>
                                            </div>
                                            <p className="form-messages mb-0 mt-3" />
                                        </form>

                                    </div>
                                    <div className="shape1">
                                        <img src="assets/img/flower_shape_1.png" alt="flower" />
                                    </div>
                                </div>



                            </aside>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />






        </>
    )
}

export default Magician;