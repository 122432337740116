import React from 'react';
import { isMobile } from "react-device-detect";
import Header from '../../Header/Header';
import MobileHeader from '../../Header/MobileHeader';
import './AboutUs.css'
import Footer from '../Footer'

function AboutUs() {
    return (
        <>
        {
                isMobile ? (<><MobileHeader /></>) : (<><Header /></>)
            }

            <section className="banner-section">
                <div
                    className="welcome-data animate-plus animated bounceInLeft"
                    data-animations="bounceInLeft"
                    data-animation-when-visible="true"
                    data-animation-reset-offscreen="false"
                    data-animation-delay="0.6s"
                    data-animation-group="_0"
                    style={{ animationDelay: "0.6s" }}
                >
                    <center>
                    <h2>About us</h2>
                    </center>
                </div>
            </section>

            <section className="about-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-lg-7">
                            <h3>Welcome to Dreams Event – Your Premier Event Experience in Lucknow!
                            </h3>
                            <p style={{ lineHeight: "35px" }}>
                                At Dreams Event, we're dedicated to turning every occasion into an unforgettable celebration. Based in Lucknow, we specialize in entertainment, decorations, and event management, offering tailored services for social events, corporate gatherings, exhibitions, conferences, virtual experiences, and vibrant New Year's parties.
                                Our expertise lies in crafting remarkable entertainment experiences. From captivating live music, energetic band performances, and engaging anchors to mesmerizing magic shows and flawlessly choreographed dance performances, we ensure your event is filled with moments that enchant and delight.
                                With a commitment to excellence, our team works tirelessly to bring your vision to life. We thrive on creativity, innovation, and personalized service, ensuring that each event we handle becomes a cherished memory.
                                Let Dreams Event transform your ideas into a reality. Because for us, every event is an opportunity to create something truly extraordinary.
                            </p>
                        </div>
                        {/*/.col*/}
                        <div className="col-md-5 col-lg-5">
                            <div className="invitation-box">
                                <div className="left-vec"></div>
                                <div className="right-vec"></div>
                                <div className="inner text-center">
                                    <img src="assets/img/book.jpg" alt="" style={{ width: "100%" }} />
                                </div>
                            </div>
                        </div>
                        {/*/.col*/}
                    </div>
                    {/*/.row*/}
                </div>
                {/*/.container*/}
            </section>
            <Footer />                      
        </>
    )
}

export default AboutUs