import React from 'react';
import './Footer.css'

function Footer() {
    return (
        <>
            <footer
                className="footer-wrapper footer-layout1 background-image"
                style={{ backgroundImage: 'url("assets/img/footer_bg_1.webp")' }}>
                <div className="widget-area">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-5">
                                <div className="widget footer-widget">
                                    <div className="as-widget-about">
                                        <div className="about-logo">
                                            <a href="/">
                                                <img src="assets/img/logo.png" width="150px" alt="Souler" />
                                            </a>
                                        </div>
                                        <p className="about-text pt-2" style={{ lineHeight: "35px" }}>
                                        Dreams specializes in Event Management & Production, We utilize our broad knowledge base in order to not only fulfill our customers’ needs, but also to collaborate with our customers to take events to the next level. 
                                        </p>
                                        <div className="as-social mt-3">
                                            <a href="https://www.facebook.com/profile.php?id=61554655453215&mibextid=ZbWKwL">
                                                <i className="fa fa-facebook-square" aria-hidden="true" />
                                            </a>
                                            <a href="https://wa.me/918948888984" target='_blank'>
                                                <i className="fa fa-whatsapp" aria-hidden="true" />
                                            </a>
                                            <a href="tel:+918948888984"><i className="fa fa-phone" aria-hidden="true" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="widget widget_nav_menu footer-widget">
                                    <h3 className="widget_title">Information</h3>
                                    <div className="menu-all-pages-container">
                                        <ul className="menu">
                                            <li>
                                                <a href="/about-us">About Us</a>
                                            </li>
                                            <li>
                                                <a href="service.html">Our Gallery</a>
                                            </li>
                                            <li>
                                                <a href="/contact-us">Contact Us</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="widget footer-widget">
                                    <h3 className="widget_title">Contact Now</h3>
                                    <div className="as-widget-contact">
                                        <div className="info-box">
                                            <div className="info-box_icon">
                                                <i className="fa fa-map-marker ttm-textcolor-skincolor" />
                                            </div>
                                            <p className="info-box_text pt-2">
                                                419/1 sector 8 Indira Nagar lucknow 226016
                                            </p>
                                        </div>
                                        <div className="info-box">
                                            <div className="info-box_icon">
                                                <i className="fa fa-phone" />
                                            </div>
                                            <p className="info-box_text pt-2">
                                                <a href="tel:+918840634068" className="info-box_link">+91-8840634068</a>
                                            </p>
                                        </div>
                                        <div className="info-box">
                                            <div className="info-box_icon">
                                                <i className="fa fa-phone" />
                                            </div>
                                            <p className="info-box_text pt-2">
                                                <a href="tel:+918948888984" className="info-box_link">+91-8948888984</a>
                                            </p>
                                        </div>
                                        <div className="info-box">
                                            <div className="info-box_icon">
                                                <i className="fa fa-envelope" aria-hidden="true" />
                                            </div>
                                            <p className="info-box_text pt-2">
                                                <a href="mailto:dreams.event41@gmail.com" className="info-box_link">
                                                    dreams.event41@gmail.com
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-wrap background-image" style={{ backgroundImage: 'url("assets/img/copyright_bg.png")' }}>
                    <div className="container">
                        <p className="copyright-text text-center">
                            Copyright <i className="fa fa-copyright" aria-hidden="true" /> 2023 By
                            <a href="https://www.digitalnawab.com/"> Digitalnawab</a>. All
                            Rights Reserved.
                        </p>
                    </div>
                </div>
            </footer>
            <div
                className="getcall mobile-fixed"
                style={{ display: "none", zIndex: 999999 }}
            >
                <div className="col-md-6 appoint">
                <a href="https://wa.me/918948888984">
                    <span>WhatsApp</span>
                </a>
                </div>
                <div className="col-md-6 call">
                <a href="tel:+918948888984">Call Now</a>
                </div>
            </div>

        </>
    )
}
export default Footer