import React from 'react';

import { isMobile } from "react-device-detect";
import Header from '../../Header/Header';
import MobileHeader from '../../Header/MobileHeader';
import Footer from '../Footer'
import './Entertainment.css';

function Anchors() {
    return (
        <>
            {
                isMobile ? (<><MobileHeader /></>) : (<><Header /></>)
            }
            <section id="exhibition">
                <div className="container">
                    <div className="main-heading mt-5">
                        <h2>Anchors</h2>
                        <h5>Dreams Event</h5>
                    </div>
                    {/*/.main heading*/}
                    {/* <ol className="breadcrumb">
                        <li>
                            <a href="index.html">home</a>
                        </li>
                        <li style={{ color: "#C78665 !important" }}>about</li>
                    </ol> */}
                </div>
                {/*/.container*/}
            </section>

            <section className="space-extra-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-8 col-lg-8">
                            <div className="page-single">
                                <div className="page-img">
                                    <img
                                        src="assets/img/Ancors.jpg"
                                        alt="Service Image"
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div className="page-content">
                                    <h2 className="h3 page-title">Anchors</h2>
                                    <p>
                                        Our charismatic anchors are the heartbeat of your event, effortlessly guiding and engaging your audience. With their impeccable hosting skills, natural charm, and a knack for keeping the atmosphere lively, our anchors add a professional yet personalized touch to your gatherings. From corporate functions to social events, they ensure seamless transitions and interactive moments, ensuring that your event flows smoothly while keeping guests entertained and engaged.
                                    </p> </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-lg-4">
                            <aside className="sidebar-area">
                                <div className="widget widget_banner">
                                    <h4 className="widget_title">Book Now</h4>
                                    <div className="widget-banner">
                                        <form
                                            action="https://angfuzsoft.com/html/souler/demo/mail.php"
                                            method="POST"
                                            className="rsvp-form"
                                        >
                                            <div className="row">
                                                <div className="form-group col-sm-12">
                                                    <input
                                                        type="text"
                                                        className="form-control style2"
                                                        name="name"
                                                        id="name"
                                                        placeholder="Your Name"
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12">
                                                    <input
                                                        type="email"
                                                        className="form-control style2"
                                                        name="email"
                                                        id="email"
                                                        placeholder="Your Email"
                                                    />
                                                </div>

                                                <div className="form-group col-sm-12">
                                                    <input
                                                        type="number"
                                                        className="form-control style2"
                                                        name="guest"
                                                        id="guest"
                                                        placeholder="Phone Number"
                                                    />
                                                </div>



                                                <div className="form-btn col-12">
                                                    <button className="btnAbout style2">SEND</button>
                                                </div>
                                            </div>
                                            <p className="form-messages mb-0 mt-3" />
                                        </form>

                                    </div>
                                    <div className="shape1">
                                        <img src="assets/img/flower_shape_1.png" alt="flower" />
                                    </div>
                                </div>



                            </aside>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Anchors;