import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Gallery from './compontent/Home/Gallery';
import AboutUs from './compontent/Home/Services/AboutUs';
import ContactUs from './compontent/Home/Services/ContactUs';
import Home from './compontent/Home/Home';
import CorporateEvents from './compontent/Home/Services/CorporateEvents';
import Decoration from './compontent/Home/Services/Decoration';
import Entertaiment from './compontent/Home/Services/Entertaiment';
import Anchors from './compontent/Home/Services/Anchors';
import LiveMusic from './compontent/Home/Services/LiveMusic';
import Choreography from './compontent/Home/Services/Choreography';
import BandPerformance from './compontent/Home/Services/BandPerformance';
import Exhibition from './compontent/Home/Services/Exhibition';
import ServiceFive from './compontent/Home/Services/ServiceFive';
import Magician from './compontent/Home/Services/Magician';
import PoliticalEvents from './compontent/Home/Services/PoliticalEvents';
import WeddingPlanner from './compontent/Home/Services/WeddingPlanner';
function App() {
  return (
    <>

      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about-us' element={<AboutUs/>}/>
          <Route path='/contact-us' element={<ContactUs/>}/>
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/political-events' element={<PoliticalEvents />} />
          <Route path='/entertainment-service' element={<Entertaiment />} />
          <Route path='/live-music' element={<LiveMusic />} />
          <Route path='/choreography' element={<Choreography />} />
          <Route path='/anchors' element={<Anchors />} />
          <Route path='/band-performance' element={<BandPerformance />} />
          <Route path='/magician' element={<Magician />} />
          <Route path='/decoration' element={<Decoration />} />
          <Route path='/exhibitions-conferences' element={<Exhibition />} />
          <Route path='/social-event' element={<ServiceFive />} />
          <Route path='/corporate-event' element={<CorporateEvents />} />
          <Route path='/wedding-planner' element={<WeddingPlanner />} />

        </Routes>
      </Router>



    </>
  )
}

export default App