import React from 'react';
import { isMobile } from "react-device-detect";
import Header from '../../Header/Header';
import MobileHeader from '../../Header/MobileHeader';
import Footer from '../Footer'
import './Entertainment.css';

function Choreography() {
    return (
        <>
            {
                isMobile ? (<><MobileHeader /></>) : (<><Header /></>)
            }
            <section id="decoration">
                <div className="container">
                    <div className="main-heading mt-5">
                        <h2>Dance Performance & Choreography</h2>
                        <h5>Dreams Event</h5>
                    </div>
                </div>
                {/*/.container*/}
            </section>

            <section className="space-extra-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-8 col-lg-8">
                            <div className="page-single">
                                <div className="page-img">
                                    <img
                                        src="assets/img/decorationmedia.webp"
                                        alt="Service Image"
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div className="page-content">
                                    <h2 className="h3 page-title">Decoration</h2>
                                    <p>
                                    Let the rhythm take center stage with our professionally choreographed dance performances. From graceful movements to energetic routines, our talented dancers bring vibrancy and elegance to your event. Whether it's a themed dance spectacle or a custom-choreographed routine, our dance performances add flair and entertainment, leaving a lasting impression on your guests and ensuring an event filled with rhythm and grace.
                                    </p>
                                    {/* <p className="mb-30">
                                        Authoritatively scale business meta-services before client-based
                                        technologies. Collaboratively strategize synergistic scenarios
                                        rather than flexible action items. Continually deliver market
                                        positioning convergence and mission-critical infrastructures.
                                    </p> */}
                                    {/* <h4 className="h4 mb-20">Our Work Process</h4>
                                    <div className="service-process-wrap mb-30">
                                        <div className="service-process-wrap mb-30">
                                            <div className="service-process">
                                                <div className="service-process_icon">
                                                    <i className="fa-thin fa-dove" />
                                                </div>
                                                <h5 className="service-process_title h6">Research and analytics</h5>
                                                <p className="service-process_text">
                                                    Credibly cultivate synergistic e commerce vis-a-vis adaptive growth
                                                    strategies.
                                                </p>
                                            </div>
                                            <div className="service-process">
                                                <div className="service-process_icon">
                                                    <i className="fa-thin fa-lights-holiday" />
                                                </div>
                                                <h5 className="service-process_title h6">Design and Idea</h5>
                                                <p className="service-process_text">
                                                    Credibly cultivate synergistic e commerce vis-a-vis adaptive growth
                                                    strategies.
                                                </p>
                                            </div>
                                            <div className="service-process">
                                                <div className="service-process_icon">
                                                    <i className="fa-thin fa-clipboard-list-check" />
                                                </div>
                                                <h5 className="service-process_title h6">All Event Manage</h5>
                                                <p className="service-process_text">
                                                    Credibly cultivate synergistic e commerce vis-a-vis adaptive growth
                                                    strategies.
                                                </p>
                                            </div>
                                        </div>


                                    </div> */}




                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-lg-4">
                            <aside className="sidebar-area">
                                <div className="widget widget_banner">
                                    <h4 className="widget_title">Book Now</h4>
                                    <div className="widget-banner">
                                        <form
                                            action="https://angfuzsoft.com/html/souler/demo/mail.php"
                                            method="POST"
                                            className="rsvp-form"
                                        >
                                            <div className="row">
                                                <div className="form-group col-sm-12">
                                                    <input
                                                        type="text"
                                                        className="form-control style2"
                                                        name="name"
                                                        id="name"
                                                        placeholder="Your Name"
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12">
                                                    <input
                                                        type="email"
                                                        className="form-control style2"
                                                        name="email"
                                                        id="email"
                                                        placeholder="Your Email"
                                                    />
                                                </div>

                                                <div className="form-group col-sm-12">
                                                    <input
                                                        type="number"
                                                        className="form-control style2"
                                                        name="guest"
                                                        id="guest"
                                                        placeholder="Phone Number"
                                                    />
                                                </div>



                                                <div className="form-btn col-12">
                                                    <button className="btnAbout style2">SEND</button>
                                                </div>
                                            </div>
                                            <p className="form-messages mb-0 mt-3" />
                                        </form>

                                    </div>
                                    <div className="shape1">
                                        <img src="assets/img/flower_shape_1.png" alt="flower" />
                                    </div>
                                </div>



                            </aside>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />






        </>
    )
}

export default Choreography;