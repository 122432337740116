import React, {useState} from 'react';
import { isMobile } from "react-device-detect";
import Header from '../../Header/Header';
import MobileHeader from '../../Header/MobileHeader';
import './ContactUs.css';
import Footer from '../Footer'

function ContactUs() {

    const [website_name, setWebsite] = useState('');
    const [send_mail, setMail] = useState('');
    const [bcc, setBcc] = useState('');
    const [username, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact_number, setContact] = useState('');
    const [service, setService] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        // Define the data you want to send
        const data = {
        website_name:"Dreamevent",
        send_email:"satyalaravel2023@gmail.com",
        bcc :"satyalaravel2023@gmail.com",
        username,
        email,
        contact_number,
        service,
        message,
        };

        console.log(data);

        // Make an HTTP POST request to the Laravel API endpoint
        fetch('https://admin.digitalnawab.com/api/gobal_email', {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            // You can handle success and error cases as needed
        })
        .catch((error) => {
            console.error(error);
        });
    };

    return (
        <>
            {
                isMobile ? (<><MobileHeader /></>) : (<><Header /></>)
            }
            <section className="contactus">
                <div className="pbmit-title-bar-wrapper contact">
                    <div className="container">
                        <div className="pbmit-tbar">
                            <div className="pbmit-tbar-inner container">
                            <h1 className="pbmit-tbar-title"> Contact Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='detail-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <center>
                                <h2>
                                    Location
                                </h2>
                                <p className='text-center'>
                                Indira Nagar lucknow 226016
                                </p>
                                </center>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <center>
                                    <h2>
                                        Phone
                                    </h2>
                                    <p className='text-center'>
                                    +91-8948888984 , +91-8840634068
                                    </p>
                                </center>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <center>
                                <h2>
                                    Email
                                </h2>
                                <p className='text-center'>                
                                   dreams.event41@gmail.com
                                </p>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="bg-title overflow-hidden" id="contact-form">
                <div className="container">
                    <div className="contact-area">
                        <div className="shape">
                            <img src="assets/img/f1.png" alt="flower" />
                        </div>
                        <div className="row flex-row-reverse">
                            <div className="col-xl-7 ps-xxl-5">
                                <div className="contact-img">
                                   <iframe width="100%" height="450px" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.8205859668524!2d80.99536717555672!3d26.87744076160528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be2af7f0e5ab1%3A0x2a45fd1b279c139b!2s419%2F1%2C%20Sector%208%2C%20Ishwar%20Puri%2C%20Indira%20Nagar%2C%20Lucknow%2C%20Uttar%20Pradesh%20226016!5e0!3m2!1sen!2sin!4v1703229168695!5m2!1sen!2sin"></iframe>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 mt-5">
                                <h2 className="sec-title text-white mb-4">Talk to Our Experts</h2>
                                <form onSubmit={handleSubmit} className="rsvp-form"
                                >
                                    <div className="row">
                                        <div className="form-group col-sm-12">
                                            <input
                                                type="text"
                                                className="form-control style2"
                                                name="username"
                                                value={username} onChange={(e) => setName(e.target.value)}  required
                                                placeholder="Your Name"
                                            />
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <input
                                                type="email"
                                                className="form-control style2"
                                                name="email"
                                                value={email} onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Your Email"
                                            />
                                        </div>

                                        <div className="form-group col-sm-12">
                                            <input
                                                type="number"
                                                className="form-control style2"
                                                name="contact_number"
                                                value={contact_number} onChange={(e) => setContact(e.target.value)}
                                                placeholder="Phone Number"
                                            />
                                        </div>

                                        <div className="form-group col-12">
                                            <select
                                                name="service"
                                                value={service} onChange={(e) => setService(e.target.value)}
                                                className="form-select style2"
                                            >
                                                <option
                                                    value=""
                                                    disabled="disabled"
                                                    selected="selected"
                                                    hidden=""
                                                >
                                                    Select Service
                                                </option>
                                                <option value="Political Events">Political Events</option>
                                                <option value="Decorations and Lightening">Decorations & Lightening</option>
                                                <option value="Exhibitions and Conferences">Exhibitions & Conferences</option>
                                                <option value="Social Events">Social Events</option>
                                                <option value="Corporate Events">Corporate Events</option>
                                                <option value="Wedding Planner">Wedding Planner</option>
                                            </select>
                                        </div>

                                        <div className="form-btn col-12">
                                            <button type="submit" className="btnAbout style2">Submit</button>
                                        </div>
                                    </div>
                                    <p className="form-messages mb-0 mt-3" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs