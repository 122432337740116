import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom';

function Header() {
  return (
    <>
      <header id="masthead" className="header ttm-header-style-01">
        {/* ttm-header-wrap */}
        <div className="ttm-header-wrap">
          {/* ttm-stickable-header-w */}
          <div
            id="ttm-stickable-header-w"
            className="ttm-stickable-header-w clearfix"
          >
            {/* ttm-topbar-wrapper */}
            <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="ttm-topbar-content">
                      <ul className="top-contact text-left">
                        <li><i className="fa fa-map-marker ttm-textcolor-skincolor" />
                          419/1 sector 8 Indira Nagar lucknow 226016
                        </li>
                        <li>
                          <i className="fa fa-envelope-o ttm-textcolor-skincolor" />
                          <a href="mailto:dreams.event41@gmail.com">dreams.event41@gmail.com</a>
                        </li>
                      </ul>
                      <div className="topbar-right text-right">
                        <div className="ttm-social-links-wrapper list-inline">
                          <ul className="social-icons">
                            <li>
                              <a
                                href="https://www.facebook.com/profile.php?id=61554655453215&mibextid=ZbWKwL"
                                className=" tooltip-bottom"
                                data-tooltip="Facebook"
                              >
                                <i className="fa fa-facebook" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="tel:+918948888984"
                                className=" tooltip-bottom"
                                data-tooltip="Twitter"
                              >
                                <i className="fa fa-phone" />
                              </a>
                            </li>

                          </ul>
                        </div>
                        <div className="header-btn">
                          <Link
                            className="ttm-btn ttm-btn-size-md ttm-btn-style-fill  ttm-btn-color-skincolor"
                            to="/contact-us"
                          >
                            Book Event!
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          {/* ttm-stickable-header-w end*/}

          {/* Navbar start */}
          <div id="site-header-menu" className="site-header-menu">
            <div className="site-header-menu-inner ttm-stickable-header visible-title">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {/* Site Navigation */}
                    <div id="site-navigation" className="site-navigation">
                      {/* Site Branding (Logo) */}
                      <div className="site-branding">
                        <Link className="home-link" to="/" title="Barber" rel="home">
                          <img id="logo-img" className="img-center lazyloaded" src="/assets/img/logo.png" alt="logo" />
                          <img className="logo-shape" src="/assets/img/logo-shape.png" alt="logo-shape" />
                        </Link>
                      </div>

                      {/* Mobile Menu Toggle */}
                      <div className="ttm-menu-toggle">
                        <input type="checkbox" id="menu-toggle-form" />
                        <label For="menu-toggle-form" className="ttm-menu-toggle-block">
                          <span className="toggle-block toggle-blocks-1" />
                          <span className="toggle-block toggle-blocks-2" />
                          <span className="toggle-block toggle-blocks-3" />
                        </label>
                      </div>

                      {/* Main Navigation Menu */}
                      <nav id="menu" className="menu">
                        <ul className="dropdown">
                          {/* Menu Items */}
                          <li><Link to="/">Home</Link></li>
                          <li><Link to="/about-us">About Us</Link></li>
                          {/* Services Menu */}
                          <li className="logo-after-this has-submenu">
                            <Link to="#">Services</Link>
                            <ul className="sub-menu">
                              {/* Entertainment Services Submenu */}
                              <li><Link to="/political-events">Political Events</Link></li>
                              <li><Link to="/decoration">Decorations & Lightening</Link></li>
                              <li><Link to="/exhibitions-conferences">Exhibitions & Conferences</Link></li>
                              <li><Link to="/social-event">Social Events</Link></li>
                              <li><Link to="/corporate-event">Corporate Events</Link></li>
                              <li><Link to="/wedding-planner">Wedding Planner</Link></li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/entertainment-service">Entertainment</Link>
                            <ul className="sub sub-menu">
                              <li><Link to="/live-music">Live Music</Link></li>
                              <li><Link to="/band-performance">Band Performance</Link></li>
                              <li><Link to="/anchors">Anchors</Link></li>
                              <li><Link to="/magician">Magician</Link></li>
                              <li><Link to="/choreography">Dance performance & Choreography</Link></li>
                            </ul>
                          </li>
                          <li><Link to="/gallery">Gallery</Link></li>
                          <li><Link to="/contact-us">Contact Us</Link></li>
                        </ul>
                      </nav>
                    </div>
                    {/* Site Navigation end*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Navbar End */}
        </div>
        {/*ttm-header-wrap end */}
      </header>
    </>
  )
}

export default Header