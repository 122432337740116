import React from 'react'
import { Link } from 'react-router-dom';

function MobileHeader() {
    return (
        <>
            <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <Link class="navbar-brand" to="/"><img
                    id="logo-img"
                    className="img-center lazyloaded"
                    src="/assets/img/logo.png"
                    alt="logo"
                    style={{ width: "40%" }}
                /></Link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item active">
                            <Link class="nav-link" to="/">Home</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/about-us">About Us</Link>
                        </li>

                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Services
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <Link class="dropdown-item" to="/political-events">Political Events
                                </Link>
                                <Link class="dropdown-item" to="/decoration">Decorations & Lightening</Link>
                                <Link class="dropdown-item" to="/exhibitions-conferences">Exhibitations & Conferences</Link>
                                <Link class="dropdown-item" to="/social-event">Social Events</Link>
                                <Link class="dropdown-item" to="/corporate-event">Corporate Events</Link>
                                <Link class="dropdown-item" to="/wedding-planner">Wedding Planner</Link>
                            </div>
                        </li>

                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Entertinment
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <Link class="dropdown-item" to="/entertainment-service">Entertinment Service</Link>
                                <Link class="dropdown-item" to="/live-music">Live Music</Link>
                                <Link class="dropdown-item" to="/band-performance">Band Performance</Link>
                                <Link class="dropdown-item" to="/anchors">Anchors</Link>
                                <Link class="dropdown-item" to="/magician">Magician</Link>
                                <Link class="dropdown-item" to="/choreography">Dance performance & Choreography</Link>
                            </div>
                        </li>
                        
                        <li class="nav-item">
                            <Link class="nav-link" to="/gallery">Gallery</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to="/contact-us">Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </nav>

        </>
    )
}

export default MobileHeader