import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';

function About() {

    return (
        <>
            <section id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-lg-7">
                            <div
                                className="welcome-data animate-plus animated bounceInLeft"
                                data-animations="bounceInLeft"
                                data-animation-when-visible="true"
                                data-animation-reset-offscreen="false"
                                data-animation-delay="0.6s"
                                data-animation-group="_0"
                                style={{ animationDelay: "0.6s" }}
                            >
                                <h2>Welcome to Dreams Event</h2>
                                <h3 className='mb-3'>Dreams specializes in Event Management</h3>
                                <p style={{ lineHeight: "35px" }}>
                                    Dreams specializes in Event Management & Production, We utilize our broad knowledge base in order to not only fulfill our customers’ needs, but also to collaborate with our customers to take events to the next level.
                                    Dreams Events is proud to rank in the top of the industry in overall customer satisfaction and continually score in the top of our industry.
                                    we help you plan everything to make organizing your event more efficient, less stressful and a whole lot more fun.
                                </p>
                                <Link to="/about-us" class="btnAbout">read more</Link>
                            </div>
                            {/*/.welcome data*/}
                        </div>
                        {/*/.col*/}
                        <div className="col-md-5 col-lg-5">
                            <div className="invitation-box">
                                <div className="left-vec"></div>
                                <div className="right-vec"></div>
                                <div className="inner text-center">
                                    <img src="assets/img/book.jpg" alt="" style={{ width: "100%" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service-sec" id="service-sec">
                <div className="container">
                    <div className="title-area text-center mb-4">
                        <span className="sub-title">
                            <img src="assets/img/title_left.svg" alt="shape" />
                             What We Do
                            <img src="assets/img/title_right.svg" alt="shape" />
                        </span>
                        <h1 className="sec-title">Our Best Services</h1>
                    </div>
                    <div className="row gy-4">
                        <div className="col-md-6 col-xl-4 col-lg-4 p-2">
                            <div className="service-box">
                                <div
                                    className="service-box_inner"
                                    data-bg-src="assets/img/service_box_bg.svg"
                                    style={{ backgroundImage: `url('assets/img/service_box_bg.svg')` }}
                                >
                                    <div
                                        className="service-box_icon"
                                        data-bg-src="assets/img/service_icon_bg_2.svg"
                                        style={{ backgroundImage: `url('assets/img/service_icon_bg_2.svg')` }}
                                    >
                                        {/* <i className="fa-solid fa-rings-wedding" /> */}
                                        <img src="assets/img/1.png" alt="" style={{ width: "22%" }} />

                                        {/* <i class="fa-solid fa-rings-wedding"></i> */}
                                    </div>
                                    <h3 className="service-box_title">
                                        <Link to="/political-events">Political Events</Link>
                                    </h3>
                                    <p className="service-box_text">
                                    Empower your political endeavors with our comprehensive event management services. From high-energy rallies to strategic campaign. 
                                    </p>
                                    <Link to="political-events" className="line-btn">
                                        Read More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4 col-lg-4 p-2">
                            <div className="service-box">
                                <div
                                    className="service-box_inner"
                                    data-bg-src="assets/img/service_box_bg.svg"
                                    style={{ backgroundImage: `url('assets/img/service_box_bg.svg')` }}
                                >
                                    <div
                                        className="service-box_icon"
                                        data-bg-src="assets/img/service_icon_bg_2.svg"
                                        style={{ backgroundImage: `url('assets/img/service_icon_bg_2.svg')` }}
                                    >
                                        <img src="assets/img/2.png" alt="" style={{ width: "22%" }} />

                                    </div>
                                    <h3 className="service-box_title">
                                        <Link to="/decoration">Decorations & Lightening</Link>
                                    </h3>
                                    <p className="service-box_text">
                                        Authoritatively predominate clicks-and mortar resources and
                                        progressive meta services. Authoritatively redefine real.
                                    </p>
                                    <Link to="/decoration" className="line-btn">
                                        Read More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4 col-lg-4 p-2">
                            <div className="service-box">
                                <div
                                    className="service-box_inner"
                                    data-bg-src="assets/img/service_box_bg.svg"
                                    style={{ backgroundImage: `url('assets/img/service_box_bg.svg')` }}
                                >
                                    <div
                                        className="service-box_icon"
                                        data-bg-src="assets/img/service_icon_bg_2.svg"
                                        style={{ backgroundImage: `url('assets/img/service_icon_bg_2.svg')` }}
                                    >
                                        <img src="assets/img/3.png" alt="" style={{ width: "22%" }} />

                                    </div>
                                    <h3 className="service-box_title">
                                        <Link to="/exhibitions-conferences">Exhibition & Conferences</Link>
                                    </h3>
                                    <p className="service-box_text">
                                    Experience exceptional exhibitions with us. Our visionary team transforms dreams into reality, crafting unforgettable events.
                                    </p>
                                    <Link to="/exhibitions-conferences" className="line-btn">
                                        Read More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4 col-lg-4 p-2">
                            <div className="service-box">
                                <div
                                    className="service-box_inner"
                                    data-bg-src="assets/img/service_box_bg.svg"
                                    style={{ backgroundImage: `url('assets/img/service_box_bg.svg')` }}
                                >
                                    <div
                                        className="service-box_icon"
                                        data-bg-src="assets/img/service_icon_bg_2.svg"
                                        style={{ backgroundImage: `url('assets/img/service_icon_bg_2.svg')` }}
                                    >
                                        <img src="assets/img/4.png" alt="" style={{ width: "22%" }} />

                                    </div>
                                    <h3 className="service-box_title">
                                        <Link to="social-event">Social Event</Link>
                                    </h3>
                                    <p className="service-box_text">
                                    We craft unforgettable moments, ensuring every second is filled with joy. Specializing in social events, we offer Concerts, Open Mics etc.
                                    </p>
                                    <Link to="social-event" className="line-btn">
                                        Read More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4 col-lg-4 p-2">
                            <div className="service-box">
                                <div
                                    className="service-box_inner"
                                    data-bg-src="assets/img/service_box_bg.svg"
                                    style={{ backgroundImage: `url('assets/img/service_box_bg.svg')` }}
                                >
                                    <div
                                        className="service-box_icon"
                                        data-bg-src="assets/img/service_icon_bg_2.svg"
                                        style={{ backgroundImage: `url('assets/img/service_icon_bg_2.svg')` }}
                                    >
                                        <img src="assets/img/5.png" alt="" style={{ width: "22%" }} />
                                    </div>
                                    <h3 className="service-box_title">
                                        <Link to="/corporate-event">Corporate Events</Link>
                                    </h3>
                                    <p className="service-box_text">
                                    Your corporate events, our comprehensive solutions. From meetings to award shows, we offer end-to-end services. 
                                    </p>
                                    <Link to="/corporate-event" className="line-btn">
                                        Read More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4 col-lg-4 p-2">
                            <div className="service-box">
                                <div
                                    className="service-box_inner"
                                    data-bg-src="assets/img/service_box_bg.svg"
                                    style={{ backgroundImage: `url('assets/img/service_box_bg.svg')` }}
                                >
                                    <div
                                        className="service-box_icon"
                                        data-bg-src="assets/img/service_icon_bg_2.svg"
                                        style={{ backgroundImage: `url('assets/img/service_icon_bg_2.svg')` }}
                                    >
                                        <img src="assets/img/6.png" alt="" style={{ width: "22%" }} />
                                    </div>
                                    <h3 className="service-box_title">
                                        <Link to="/live-music">Wedding planner</Link>
                                    </h3>
                                    <p className="service-box_text">
                                        Welcome to the epitome of  weddings. We specialize in curating dream weddings that reflect your unique love story.
                                    </p>
                                    <Link to="/wedding-planner" className="line-btn">
                                        Read More
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div
                                className="banner-content animate-plus animated zoomIn"
                                data-animations="zoomIn"
                                data-animation-when-visible="true"
                                data-animation-reset-offscreen="false"
                                data-animation-delay="0.3s"
                                data-animation-group="_2"
                                style={{ animationDelay: "0.3s" }}
                            >
                                <h3>
                                    wedding <span>solutions</span>
                                </h3>
                                <p class="mb-4">
                                    we have professional wedding specialists who will take good care of
                                    your event and your money
                                </p>
                                <Link style={{ marginTop: "120px" }} to="/contact-us" className="btnAbout">
                                    contact now
                                </Link>
                            </div>
                            {/*/.banner content*/}
                        </div>
                        {/*/.col*/}
                    </div>
                    {/*/.row*/}
                </div>
                {/*/.container*/}
            </section>
            <div className="bg-title overflow-hidden" id="contact-sec">
                <div className="container">
                    <div className="contact-area">
                        <div className="shape">
                            <img src="assets/img/f1.png" alt="flower" />
                        </div>
                        <div className="row flex-row-reverse">
                            <div className="col-xl-7 ps-xxl-5">
                                <div className="contact-img">
                                    <img src="assets/img/contact_1.png" alt="contact" style={{ width: "100%" }} />
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 mt-5">
                                <h2 className="sec-title text-white mb-4">Talk to Our Experts</h2>
                                <form
                                    action="https://angfuzsoft.com/html/souler/demo/mail.php"
                                    method="POST"
                                    className="rsvp-form"
                                >
                                    <div className="row">
                                        <div className="form-group col-sm-12">
                                            <input
                                                type="text"
                                                className="form-control style2"
                                                name="name"
                                                id="name"
                                                placeholder="Your Name"
                                            />
                                        </div>
                                        <div className="form-group col-sm-12">
                                            <input
                                                type="email"
                                                className="form-control style2"
                                                name="email"
                                                id="email"
                                                placeholder="Your Email"
                                            />
                                        </div>

                                        <div className="form-group col-sm-12">
                                            <input
                                                type="number"
                                                className="form-control style2"
                                                name="guest"
                                                id="guest"
                                                placeholder="Phone Number"
                                            />
                                        </div>

                                        <div className="form-group col-12">
                                            <select
                                                name="subject"
                                                id="subject"
                                                className="form-select style2"
                                            >
                                                <option
                                                    value=""
                                                    disabled="disabled"
                                                    selected="selected"
                                                    hidden=""
                                                >
                                                    Select Event
                                                </option>
                                                <option value="Political Events">Political Events</option>
                                                <option value="Decorations and Lightening">Decorations & Lightening</option>
                                                <option value="Exhibitions and Conferences">Exhibitions & Conferences</option>
                                                <option value="Social Events">Social Events</option>
                                                <option value="Corporate Events">Corporate Events</option>
                                                <option value="Wedding Planner">Wedding Planner</option>
                                            </select>
                                        </div>

                                        <div className="form-btn col-12">
                                            <button className="btnAbout style2">Submit</button>
                                        </div>
                                    </div>
                                    <p className="form-messages mb-0 mt-3" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About