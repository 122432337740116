import React from 'react'
import Header from '../Header/Header'
import HomeSlider from '../Header/HomeSlider'
import About from './About'
import Footer from './Footer'
import { isMobile } from "react-device-detect";
import MobileHeader from '../Header/MobileHeader'


function Home() {
    return (
        <>
           {
            isMobile ?(<><MobileHeader/></>):(<><Header/></>)
           }

            <HomeSlider />

            <About />
         
            <Footer />
        </>

    )
}

export default Home