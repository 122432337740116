import React from 'react'
import Header from '../Header/Header'
import Footer from './Footer';
import './Gallery.css';
import { isMobile } from "react-device-detect";
import MobileHeader from '../Header/MobileHeader';

function Gallery() {
    return (
        <>
            {
                isMobile ? (<><MobileHeader /></>) : (<><Header /></>)
            }

            <section id="page-heading">
                <div className="container">
                    <div className="main-heading">
                        <h2>Gallery</h2>
                        <h5>Dreams Event</h5>
                    </div>
                    {/*/.main heading*/}
                    {/* <ol className="breadcrumb">
                        <li>
                            <a href="index.html">home</a>
                        </li>
                        <li style={{ color: "#C78665 !important" }}>about</li>
                    </ol> */}
                </div>
                {/*/.container*/}
            </section>

            <section className="service-sec space" id="service-sec" style={{ backgroundImage: `url('assets/img/bg-1.png')` }}>
                <div className="container-fluid">
                    <div className="title-area text-center">
                        <span className="sub-title">
                            <img src="assets/img/title_left.svg" alt="shape" />
                            Our Gallery
                            <img src="assets/img/title_right.svg" alt="shape" />
                        </span>
                        <h1 className="sec-title mb-5">Lovely Captured Moments</h1>
                    </div>
                    <div className="row gy-4 mb-5" >
                        <ul class="grid">

                            <li>
                                <a data-fancybox="gallery" data-src="https://images.pexels.com/photos/6479596/pexels-photo-6479596.jpeg?auto=compress&cs=tinysrgb&w=1600">
                                    <img

                                        src="https://images.pexels.com/photos/6479596/pexels-photo-6479596.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                    />
                                </a>
                            </li>
                            <li>
                                <a data-fancybox="gallery" data-src="https://images.pexels.com/photos/4959831/pexels-photo-4959831.jpeg?auto=compress&cs=tinysrgb&w=1600">
                                    <img

                                        src="https://images.pexels.com/photos/4959831/pexels-photo-4959831.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                    />
                                </a>
                            </li>
                            <li>
                                <a data-fancybox="gallery" data-src="https://images.pexels.com/photos/5005252/pexels-photo-5005252.jpeg?auto=compress&cs=tinysrgb&w=1600">
                                    <img

                                        src="https://images.pexels.com/photos/5005252/pexels-photo-5005252.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                    />
                                </a>
                            </li>
                            <li>
                                <a data-fancybox="gallery" data-src="https://images.pexels.com/photos/4992830/pexels-photo-4992830.jpeg?auto=compress&cs=tinysrgb&w=1600">
                                    <img

                                        src="https://images.pexels.com/photos/4992830/pexels-photo-4992830.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                    />
                                </a>
                            </li>
                            <li>
                                <a data-fancybox="gallery" data-src="https://images.pexels.com/photos/673659/pexels-photo-673659.jpeg?auto=compress&cs=tinysrgb&w=1600">
                                    <img

                                        src="https://images.pexels.com/photos/673659/pexels-photo-673659.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                    />
                                </a>
                            </li>
                            <li>
                                <a data-fancybox="gallery" data-src="https://images.pexels.com/photos/673659/pexels-photo-673659.jpeg?auto=compress&cs=tinysrgb&w=1600">
                                    <img

                                        src="https://images.pexels.com/photos/673659/pexels-photo-673659.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                    />
                                </a>
                            </li>
                            <li>
                                <a data-fancybox="gallery" data-src="https://images.pexels.com/photos/1186116/pexels-photo-1186116.jpeg?auto=compress&cs=tinysrgb&w=1600">
                                    <img

                                        src="https://images.pexels.com/photos/1186116/pexels-photo-1186116.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                    />
                                </a>
                            </li>
                            <li>
                                <a data-fancybox="gallery" data-src="https://images.pexels.com/photos/8819336/pexels-photo-8819336.jpeg?auto=compress&cs=tinysrgb&w=1600">
                                    <img

                                        src="https://images.pexels.com/photos/8819336/pexels-photo-8819336.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                    />
                                </a>
                            </li>
                            <li>
                                <a data-fancybox="gallery" data-src="https://images.pexels.com/photos/5610374/pexels-photo-5610374.jpeg?auto=compress&cs=tinysrgb&w=1600">
                                    <img

                                        src="https://images.pexels.com/photos/5610374/pexels-photo-5610374.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                    />
                                </a>
                            </li>

                        </ul>

                    </div>
                </div>
            </section>
            <Footer />

        </>
    )
}

export default Gallery