import React from 'react';
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function HomeSlider() {


    const sliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        infinite: true,
    }

    return (
        <>

            <Slider {...sliderSettings}>
                <img src="assets/img/banner1.webp" alt="" />
                <img src="assets/img/banner3.webp" alt="" />
                {/* <img src="assets/img/banner.jpg" alt="" /> */}
            </Slider>

        </>
    )
}

export default HomeSlider